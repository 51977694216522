.App {
  text-align: center;
}

.container {
  max-width: 800px;
  margin: 0 auto;
}

.heading {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.service {
  font-weight: bold;
  margin-bottom: 1rem;
}

.indicator {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  border-radius: 50%;
}

.green {
  background-color: #28a745;
}

.yellow {
  background-color: #ffd33d;
}

.red {
  background-color: #cb2431;
}

.gray {
  background-color: #6a737d;
}

.status {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  font-weight: 400;
  line-height: 1.5;
}

.subheading {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

hr {
  border: none;
  border-top: 1px solid #dfe2e5;
  margin: 1rem 0;
}

.footer {
  margin-top: 2rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6a737d;
}
